.collapse-header {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  border-top: 3px solid black;
}

.collapse-list {
  padding-left: 10px;
}

.collapse-list.collapsed {
  height: 0;
  overflow: hidden;
}
