.async-select-container .async-select__control,
.async-select-container .async-select-valid__control,
.async-select-container .async-select-invalid__control {
  min-height: unset !important;
  height: auto;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
  box-shadow: unset;
}

.async-select-container .async-select-valid__control {
  border: 1px solid #28a745;
}

.async-select-container .async-select-invalid__control {
  border: 1px solid #dc3545;
}

.async-select-container .async-select__control:hover {
  border: 1px solid #ced4da;
}

.async-select-container .async-select-valid__control:hover {
  border: 1px solid #28a745;
}

.async-select-container .async-select-invalid__control:hover {
  border: 1px solid #dc3545;
}

.async-select-container .async-select__control.async-select__control--is-focused,
.async-select-container
  .async-select-valid__control.async-select-valid__control--is-focused
  .async-select-container
  .async-select-invalid__control.async-select-invalid__control--is-focused {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.async-select-container .async-select__control .async-select__indicator,
.async-select-container .async-select-valid__control .async-select-valid__indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator {
  padding: 0;
}

.async-select-container .async-select__control .async-select__indicator.async-select__clear-indicator,
.async-select-container .async-select-valid__control .async-select-valid__indicator.async-select-valid__clear-indicator,
.async-select-container
  .async-select-invalid__control
  .async-select-invalid__indicator.async-select-invalid__clear-indicator {
  cursor: pointer;
}

.async-select-container .async-select__control .async-select__indicator svg,
.async-select-container .async-select-valid__control .async-select-valid__indicator svg,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator svg {
  scale: 0.8;
  fill: #515151;
}

.async-select-container .async-select__control .async-select__indicator-separator,
.async-select-container .async-select-valid__control .async-select-valid__indicator-separator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator-separator {
  display: none;
}

.async-select-container .async-select__control .async-select__value-container,
.async-select-container .async-select-valid__control .async-select-valid__value-container,
.async-select-container .async-select-invalid__control .async-select-invalid__value-container {
  padding: 0.25rem 0.5rem;
}

.async-select-container .async-select__control .async-select__value-container .async-select__input-container,
.async-select-container
  .async-select-valid__control
  .async-select-valid__value-container
  .async-select-valid__input-container,
.async-select-container
  .async-select-invalid__control
  .async-select-invalid__value-container
  .async-select-invalid__input-container {
  padding: 0;
  margin: 0;
}

.custom-select-container .custom-select__control,
.custom-select-container .custom-select-valid__control,
.custom-select-container .custom-select-invalid__control {
  min-height: unset !important;
  height: auto;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
  box-shadow: unset;
}

.custom-select-container .custom-select-valid__control {
  border: 1px solid #28a745;
}

.custom-select-container .custom-select-invalid__control {
  border: 1px solid #dc3545;
}

.custom-select-container .custom-select__control:hover {
  border: 1px solid #ced4da;
}

.custom-select-container .custom-select-valid__control:hover {
  border: 1px solid #28a745;
}

.custom-select-container .custom-select-invalid__control:hover {
  border: 1px solid #dc3545;
}

.custom-select-container .custom-select__control.custom-select__control--is-focused,
.custom-select-container
  .custom-select-valid__control.custom-select-valid__control--is-focused
  .custom-select-container
  .custom-select-invalid__control.custom-select-invalid__control--is-focused {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.custom-select-container .custom-select__control .custom-select__indicator,
.custom-select-container .custom-select-valid__control .custom-select-valid__indicator,
.custom-select-container .custom-select-invalid__control .custom-select-invalid__indicator {
  padding: 0;
}

.custom-select-container .custom-select__control .custom-select__indicator.custom-select__clear-indicator,
.custom-select-container
  .custom-select-valid__control
  .custom-select-valid__indicator.custom-select-valid__clear-indicator,
.custom-select-container
  .custom-select-invalid__control
  .custom-select-invalid__indicator.custom-select-invalid__clear-indicator {
  cursor: pointer;
}

.custom-select-container .custom-select__control .custom-select__indicator svg,
.custom-select-container .custom-select-valid__control .custom-select-valid__indicator svg,
.custom-select-container .custom-select-invalid__control .custom-select-invalid__indicator svg {
  scale: 0.8;
  fill: #515151;
}

.custom-select-container .custom-select__control .custom-select__indicator-separator,
.custom-select-container .custom-select-valid__control .custom-select-valid__indicator-separator,
.custom-select-container .custom-select-invalid__control .custom-select-invalid__indicator-separator {
  display: none;
}

.custom-select-container .custom-select__control .custom-select__value-container,
.custom-select-container .custom-select-valid__control .custom-select-valid__value-container,
.custom-select-container .custom-select-invalid__control .custom-select-invalid__value-container {
  padding: 0.25rem 0.5rem;
}

.custom-select-container .custom-select__control .custom-select__value-container .custom-select__input-container,
.custom-select-container
  .custom-select-valid__control
  .custom-select-valid__value-container
  .custom-select-valid__input-container,
.custom-select-container
  .custom-select-invalid__control
  .custom-select-invalid__value-container
  .custom-select-invalid__input-container {
  padding: 0;
  margin: 0;
}

.allowed-file-types-label {
  font-size: 10px;
  opacity: 0.8;
}

.email-resent-message {
  font-size: 12px;
  opacity: 0.8;
}

.refunds-not-allowed-row {
  opacity: 0.7;
}

.refunds-summary-row td {
  font-weight: 600;
}

.refunds-with-fee-btn {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  line-height: 12.5px;
}

.refunds-with-fee-btn span:first-child {
  white-space: nowrap;
}

.refunds-payment-details {
  font-size: 12px;
}

.payment-method-form {
  font-size: 12px;
}

.refunds-payment-method-card {
  position: relative;
  font-size: 10px;
  margin-bottom: 10px;
}

.refunds-payment-method-card .primary-payment-method-indicator {
  position: absolute;
  display: block;
  top: 5px;
  right: 5px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #28a745;
}

.payment-method-form .col-sm-7 {
  padding-right: 5px;
}

.payment-method-form .col-sm-5 {
  padding-left: 5px;
}

.refunds-payment-details-wrapper > .col-md-4 {
  padding-left: 5px;
  padding-right: 5px;
}

.refunds-payment-details-wrapper > .col-md-4:first-child {
  padding-left: 15px;
}

.refunds-payment-details-wrapper > .col-md-4:last-child {
  padding-right: 15px;
}

.monitoring-wrapper .col-md-8 {
  padding-right: 5px;
}

.monitoring-wrapper .col-md-4 {
  padding-left: 5px;
}

.tab-status-indicator {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-bottom: -2px;
  margin-left: 6px;
  border-radius: 50%;
}

.text-success {
  color: green;
}

.bg-success {
  background-color: green;
  box-shadow: 0 0 8px 3px lightgreen;
}

.text-warning {
  color: yellow;
}

.bg-warning {
  background-color: yellow;
  box-shadow: 0 0 8px 3px #e9e991;
}

.text-danger {
  color: #dc3545;
}

.bg-danger {
  background-color: #dc3545;
  box-shadow: 0 0 8px 3px #f19ca5;
}

.bg-default {
  background-color: #9e9e9e;
  box-shadow: 0 0 8px 3px #b9b9b9;
}

@media (max-width: 768px) {
  .monitoring-wrapper .col-md-8 {
    padding-right: 15px;
  }

  .monitoring-wrapper .col-md-4 {
    padding-left: 15px;
  }

  .refunds-payment-details-wrapper > .col-md-4 {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .payment-method-form .col-sm-7 {
    padding-right: 15px;
  }

  .payment-method-form .col-sm-5 {
    margin-top: 10px;
    padding-left: 15px;
  }
}
