#sidebar {
  .nav {
    &.submenu {
      padding-left: 10px;
      margin-top: 0;

      i {
        font-size: 24px;
      }

      p {
        font-size: 11px;
      }
    }
  }
}

a {
  color: unset !important;
}

.go-link {
  text-decoration: underline;
  color: #007bff !important;
}

.loading-spinner {
  .spinner-border {
    width: 5em;
    height: 5em;
  }
}

.pagination {
  .page-item {
    &.active {
      span {
        background-color: $primary-bg;
        color: white;
      }
    }
  }
}

.orders-table {
  a {
    &:hover {
      text-decoration: underline;
      background-color: unset;
    }
  }

  .table-row {
    background-color: #ffffff;
  }

  .table-bg-AttemptedNeedsInfo,
  .table-bg-Hold,
  .table-bg-Error {
    background-color: #fdc064;
  }

  .table-bg-PendingRA,
  .table-bg-Processing {
    background-color: #4fc3f7;
  }

  .table-bg-Completed {
    background-color: #a5d6a7;
  }

  .table-bg-Cancelled {
    background-color: #e57373;
  }

  .table-bg-SubmittedToState {
    background-color: #b78fff;
  }
}

.orderinfo {
  background-color: white;

  h4 {
    font-weight: bold;
  }

  .form-group {
    margin-bottom: 5px;

    &.sub-form {
      border: 1px solid lightgray;
      padding: 10px 0;
      position: relative;
      margin-bottom: 10px;

      .title {
        position: absolute;
        top: -10px;
        padding: 0 5px;
        left: 10px;
        background-color: white;
        font-weight: bold;
      }
    }
  }
}

.form-border {
  border: 1px solid;
  border-bottom: 1px solid #dee2e6 !important;
  border-color: #dee2e6 #dee2e6 #fff;
  padding: 10px;
}

table {
  tr {
    cursor: unset;
  }

  .info-row {
    cursor: unset;

    a:hover {
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    }
  }
}

.readonly {
  outline: none;
  border: none;
}

.css-1r4vtzz,
.css-48ayfv {
  width: 100% !important;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem !important;
  border: 1px solid #ced4da !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.indicator {
  display: flex;
  align-items: center;
}

.indicator-status {
  display: inline-block;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  margin-right: 5px;

  &.status-red {
    background-color: var(--danger);
  }

  &.status-yellow {
    background-color: var(--warning);
  }

  &.status-green {
    background-color: var(--success);
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0;
    font-weight: bold;
  }

  img {
    width: 80px;
  }
}

.main-content {
  margin-top: 67px;
  padding-top: 20px;

  &.zendeskaccounts {
    .table-responsive {
      max-height: calc(100vh - 200px);
    }
  }

  &.ein-options {
    padding-bottom: 50px;
  }
}

.fixed-height {
  &.action-log {
    .table-responsive {
      max-height: calc(100vh - 150px);
      overflow-y: scroll;
    }
  }

  &.third-party {
    .table-responsive {
      max-height: calc(100vh - 150px);
      overflow-y: scroll;
    }
  }

  &.healthcheck {
    .table-responsive {
      max-height: calc(100vh - 340px);
      overflow-y: scroll;

      table {
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.consumer {
    .table-responsive {
      max-height: calc(100vh - 490px);
    }
  }

  &.notes {
    .table-responsive {
      max-height: calc(100vh - 440px);
      border-bottom: 1px solid lightgray;
      overflow-y: overlay;

      table {
        margin: 0;

        th:first-child {
          width: 150px;
        }

        p {
          margin: 0;
          white-space: break-spaces;
        }
      }
    }
  }

  &.tickets {
    background-color: white;

    .left-side {
      div.row {
        margin: 10px 0;

        .dropdown-item {
          padding: 0;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .table-responsive {
      max-height: calc(100vh - 250px);

      .dropdown-item {
        padding: 0;
        font-weight: bold;
        color: #007bff !important;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .zendesk-authorize {
      * {
        font-weight: bold;
      }

      img {
        max-width: 100px;
        display: block;
        margin: auto;
      }

      button {
        margin: auto;
        margin-top: 30px;
      }
    }
  }
}

.dropdown-item {
  &:active {
    background-color: unset;
  }
}

.dropdown-item-text {
  &:active {
  }
  &:hover {
  }
}

.login-form {
  margin-top: 15%;
}

.profile-form {
  .row {
    align-items: center;
  }
}

.summary-counts {
  ul {
    list-style-type: none;

    li {
      margin: 5px 0;
    }
  }
}

#ticket_header {
  display: flex;
  align-items: center;

  .ticket-title {
    font-weight: bold;
    margin: 0;

    .channel {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

#ticket_comments {
  border-left: 1px solid lightgray;
  overflow-y: auto;
  padding: 5px 10px;

  .comment {
    white-space: break-spaces;
    display: flex;

    span {
      position: relative;

      .avarta {
        width: 40px;
        border: 1px solid lightgray;
        border-radius: 100%;
        margin-right: 10px;
      }

      svg {
        position: absolute;
        top: 25px;
        left: 25px;
      }
    }

    .sender_name {
      font-weight: bold;

      .agent-email {
        font-size: 12px;
        font-style: italic;
      }
    }

    .message_time {
      font-size: 12px;
    }

    .attached_files {
      padding: 5px;

      .comment-attached {
        display: inline-block;
        width: fit-content;
        border: 1px solid lightgray;
        padding: 0 5px;
        border-radius: 5px;

        .header {
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 20px;
          }
        }

        .filename {
          font-size: 14px;
          border-top: 1px solid lightgray;
        }
      }
    }
  }
}

.ticket-form {
  form {
    width: 100%;

    .tool-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;

      i {
        font-size: 20px;
        cursor: pointer;
      }
      .ticket_status {
        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .dropdown-item {
          &::before {
            display: inline-block;
            vertical-align: baseline;
            content: "";
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }

          &.open::before {
            background-color: $danger-color !important;
          }

          &.pending::before {
            background-color: $primary-color !important;
          }

          &.solved::before {
            background-color: $default-color !important;
          }
        }
      }
    }
  }
}

.addnewaccount {
  .h4 {
    margin: 0;
    font-weight: bold;
  }
}

div.ReactTags__tags {
  position: relative;
}

div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;

  input {
    &.ReactTags__tagInputField,
    &.ReactTags__tagInputField:focus {
      height: 31px;
      margin: 0;
      font-size: 12px;
      width: 100%;
      border: 1px solid #eee;
      padding: 0 4px;
    }
  }
}

/* Styles for selected tags */
div.ReactTags__selected {
  span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
  }
}
div.ReactTags__selected {
  button.ReactTags__remove {
    color: #aaa;
    border: none;
    margin-left: 5px;
    cursor: pointer;
  }
}

.ads-accounts-table {
  .table-responsive {
    height: calc(100vh - 3rem - 105px);
    overflow-y: scroll;

    tbody {
      tr {
        td {
          word-break: break-word;

          &:last-child {
            word-break: unset;
          }
        }
      }
    }
  }
}

.ein-options {
  .tab-pane {
    .row {
      background-color: white;
    }
  }

  .offers-table {
    .table-responsive {
      height: calc(100vh - 150px);
      overflow-y: scroll;
    }
  }

  .option-wrapper {
    padding: 10px;

    label {
      font-weight: bold;
    }

    .schedule-form {
      margin: 10px;

      span {
        margin: 0 10px;
      }
    }
  }
}

.upselloffer-availablity {
  cursor: pointer;
  margin: 0 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 1px solid;
  border-radius: 50%;

  &.active {
    background-color: #aae1ff;
    border-color: #aae1ff;
  }
}

.timezone-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 0;

  label {
    margin: 0;
    margin-right: 10px;
  }
}

.userlogstable,
.thirdpartylogstable {
  td {
    vertical-align: top !important;
    text-align: left;
  }
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.tab-pane {
  background-color: #ffffff;
  padding-bottom: 50px;
}

.form-control:valid,
.form-control:invalid {
  background-position: right calc(0.1em + 0.1875rem) center !important;
  background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem) !important;
  padding: 0.25rem 0.5rem !important;
  padding-right: calc(0.5em + 0.75rem) !important;
}

select.form-control:valid,
select.form-control:invalid {
  background-position: right calc(0.9em + 0.1875rem) center !important;
}
